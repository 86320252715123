import { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat'
import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  MessageInput,
  MessageList,
  Streami18n,
  useChannelActionContext,
  useChannelStateContext,
  useCreateChatClient,
  Window,
} from 'stream-chat-react'

import 'stream-chat-react/dist/css/v2/index.css'
import React, { useEffect, useState } from 'react'
import { Button, Label, Modal } from 'semantic-ui-react'
import { LocalDB } from '../utils/LocalDB'
import { SignJWT } from 'jose'
import { push_toast } from '../utils/common'

const apiKey = 'jqgrkgztmzh6'
const apiSecret = 'f5u4ub7qfyqzx6y5bx6u7p7wvwer25gz9zszk4sxxw33ufbyupnbgkm2faf33vsw'

const i18nInstance = new Streami18n({ language: 'pt' })

const ChannelInner = () => {
  const { addNotification } = useChannelActionContext()
  const { channel } = useChannelStateContext()
  useEffect(() => {
    const clickToAddNotification = () => {
      addNotification('A message has been edited!', 'success')
    }
    channel.on('message.updated', clickToAddNotification)
    return () => {
      channel.off('message.updated', clickToAddNotification)
    }
  }, [addNotification, channel])

  return (
    <>
      <Window>
        <ChannelHeader/>
        <MessageList/>
        <MessageInput/>
      </Window>
    </>
  )
}

const ChatContainer = ({ client, ...props }: any) => {
  let cur_user = LocalDB.getItem('current_user')
  const userId = `${cur_user.team.schema_name}___gater`

  const sort: ChannelSort = { last_message_at: -1 }
  const filters: ChannelFilters = {
    type: 'condoid_messaging',
    members: { $in: [userId] },
  }
  const options: ChannelOptions = {
    limit: 10,
    presence: true,
    state: true
  }

  if (!client) return <div>Carregando chat...</div>

  return (
    <>
      <div className="chat-app-container">
        <Modal.Content>
          <div className="chat-app">

            <Chat client={client} i18nInstance={i18nInstance} defaultLanguage="pt">

              <ChannelList
                filters={filters} sort={sort} options={options}
                showChannelSearch
                additionalChannelSearchProps={{ searchForChannels: true }}
              />
              <Channel>
                <ChannelInner/>
              </Channel>
            </Chat>
          </div>
        </Modal.Content>
      </div>

      <Modal.Actions>
        <Button color="red" size="large" onClick={props.onClose} content="Fechar"/>

      </Modal.Actions>
    </>
  )
}

const ChatButtonContainer = (props: any) => {
  const [open, setOpen] = useState(false)

  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: props.token,
    userData: { id: props.user_id },
  })

  useEffect(() => {
    console.log('Chat Events Init')
    let event_listener: any = null

    if (client) {
      console.log('Chat Events Conected')

      event_listener = client.on('message.new', (event) => {
        console.log('event', event)

        if (!open && event.message?.user?.id.indexOf('gater') === -1) {
          push_toast(`Nova mensagem no chat: ${event?.message?.text}`, 'info')
        }
      })
    }

    return () => {
      if (event_listener) {
        event_listener.unsubscribe()
      }
    }
  }, [client, open])

  return (
    <>
      <Label size="large" color="violet" onClick={() => setOpen(true)}>
        CHAT
      </Label>

      {open &&
        <Modal open={open} size="large">
          <ChatContainer client={client} onClose={() => setOpen(false)}/>
        </Modal>}
    </>
  )
}

export const ChatButtonMenu = () => {
  const [token, setToken] = useState('')
  let cur_user = LocalDB.getItem('current_user')
  const userId = `${cur_user.team.schema_name}___gater`

  useEffect(() => {
    (async () => {

      const secret = new TextEncoder().encode(apiSecret)
      const payload = {
        user_id: userId, // ID do usuário ou entidade
      }

      // Criando o token
      const jwt = await new SignJWT(payload).setProtectedHeader({ alg: 'HS256' }) // Algoritmo de assinatura
        // .setIssuedAt() // Adiciona o tempo atual como "emitido em"
        // .setExpirationTime('2h') // Define o tempo de expiração
        .sign(secret)

      console.log(jwt)
      setToken(jwt)
    })()

  }, [])

  return (
    <>
      {token && <ChatButtonContainer user_id={userId} token={token}/>}
    </>
  )
}