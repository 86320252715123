import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Modal } from 'semantic-ui-react'
import { FF, FormikInput, FormikSelect } from '../../components/form/FormInputs'
import UnitsSelect from '../../components/form/UnitsSelect'
import ActivityLoading from '../../components/Loading'
import { useDispatch } from 'react-redux'
import { actionMap as system_actions } from '../../redux/modules/system'
import AccessReasonSelect from '../../components/form/AccessReasonSelect'
import { useQuery } from 'react-query'
import { qs } from '../../queries'
import { WebcamCaptureUploader } from '../../components/WebcamCapture'
import { show_modal } from '../../utils/Formatters'
import moment from 'moment/moment'
import { toast as toastr } from 'react-toastify'
import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'

const AccessLogFormPage = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [guest_data, setGuestData] = useState([])
  const guests_query = useQuery<any[]>('getGuestsCached', () => qs.guests.allCached())

  useEffect(() => {
    if (Array.isArray(guests_query.data)) {
      let new_guest_data = guests_query.data.map(i => {
        return {
          id: i.id,
          name: `${i.name}   |   CPF: ${i.cpf || ''}   |   RG: ${i.rg || ''}   |   COD: ${i.obj_number}`
        }
      })

      setGuestData(new_guest_data)
    }

  }, [guests_query.data])

  const closeModal = () => {
    dispatch(system_actions.showAccessLogForm(false))
    dispatch(system_actions.showAccessLogFastForm(false))
  }

  return (
    <Modal size={'large'} open={true}>
      <Formik
        initialValues={{
          _add_guest: false,

          go_condo: false,
          destination_unit: '',
          person: '',
          fast_guest_name: '',
          fast_guest_cpf: '',
          fast_guest_rg: '',
          access_reason: '',
          file: ''
        }}
        validate={values => {
          let errors: any = {}

          if (!values.access_reason) errors.access_reason = true
          if (values.go_condo === false && !values.destination_unit) errors.destination_unit = true

          if (values._add_guest) {
            if (!values.fast_guest_name) errors.fast_guest_name = true
          } else {
            if (!values.person) errors.person = true
          }

          console.log(errors)

          return errors
        }}
        validationSchema={() => {
          return Yup.lazy((values: any) => {
            return Yup.object().shape({
              // @ts-ignore
              fast_guest_cpf: values.fast_guest_cpf ? Yup.string().validCPF() : Yup.string()
            })
          })
        }}
        onSubmit={async (values) => {
          try {
            setLoading(true)

            let payload = JSON.parse(JSON.stringify(values))

            if (values._add_guest) {
              let guest_res = await qs.guests.post({
                name: values.fast_guest_name,
                cpf: values.fast_guest_cpf,
                rg: values.fast_guest_rg,
                file: values.file
              })

              payload['person'] = guest_res.id
            }

            payload['date'] = moment().toISOString()
            payload['type'] = 1
            payload['status'] = 3

            const response_data = await qs.access_logs.createEntrance([payload])

            console.log('response_data', response_data)

            setLoading(false)
            show_modal('Visitante registrado com sucesso!', 'success')
            closeModal()

          } catch (e) {
            setLoading(false)
            show_modal('Erro ao processar a solicitação!', 'error')
          }
        }}
        render={({ values, setFieldValue, submitForm, handleBlur, errors, touched }) => (
          <>
            <Modal.Header>Registro Rápido de Visitante</Modal.Header>
            <Modal.Content scrolling>
              <Form className="ui form large error">
                <Checkbox toggle label={<label>Visitando o Condomínio</label>} onClick={(a, b) => {
                  setFieldValue('go_condo', b.checked)
                  setFieldValue('destination_unit', '')
                }}/>

                <br/>
                <br/>

                {!values.go_condo && <UnitsSelect name="destination_unit"/>}

                <Checkbox toggle label={'Cadastrar novo visitante'} onClick={(a, b) => {
                  setFieldValue('_add_guest', b.checked)
                }}/>

                <br/>
                <br/>

                {!values._add_guest && <>
                  <Field
                    label={'Visitantes Cadastrados (Pesquise por Nome, CPF, RG ou Código)'}
                    name={'person'}
                    width={undefined}
                    component={FormikSelect}
                    options={guest_data}
                    search={true}
                    id_option="id"
                    label_option="name"
                  />
                </>}

                {values._add_guest && <>
                  <FF label="Nome" name="fast_guest_name" component={FormikInput}/>

                  <Form.Field error={!!errors.fast_guest_cpf}>
                    <label>CPF</label>
                    <MaskedInput
                      name="fast_guest_cpf" value={values.fast_guest_cpf}
                      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                      onChange={(event) => setFieldValue('fast_guest_cpf', event.target.value.replace(new RegExp(/\D/g), ''))}
                      onBlur={handleBlur}
                    />

                    {touched.fast_guest_cpf && errors.fast_guest_cpf && <h5 className="ui header red">{errors.fast_guest_cpf}</h5>}
                  </Form.Field>

                  <FF label="RG" name="fast_guest_rg" component={FormikInput}/>

                  <WebcamCaptureUploader
                    values={values}
                    setFieldValue={setFieldValue}
                    size="medium"
                  />
                </>}


                <Form.Group widths="equal">
                  <AccessReasonSelect/>
                  <FF label={'Observações da Visita'} name="description" component={FormikInput}/>
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <button className="ui red button" onClick={closeModal}>Cancelar</button>
              <button className="ui button primary" onClick={submitForm}>Registrar Entrada</button>
            </Modal.Actions>
          </>
        )}
      />

      <ActivityLoading visible={loading}/>
    </Modal>
  )

}

export default AccessLogFormPage
