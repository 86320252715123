import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Grid, Header, Label, Table } from 'semantic-ui-react'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import { ProfileCache } from '../../components/Utils'
import { qs } from '../../queries'
import { qs_local_server } from '../../queries-local-server'
import { history } from '../../redux/store/configureStore'
import { chunkArray } from '../../utils/common'
import { hasValue } from '../../utils/Formatters'
import { AccessManagerRequirements } from '../../utils/HardwareService'
import { localPostApi, localPostApi_ValidateLocalServer } from '../../utils/LocalApi'

// ao acionar estar remotando e atualizando os equipamentos tudo de novo

const LivePage = () => {
  const { data } = useQuery('getLastEvents', () => qs_local_server.events.lastEvents())
  const [profiles_person, setProfilesPerson] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)

  const _lastLog = () => {
    try {
      return data ? data[0] : null
    } catch (e) {
      return null
    }
  }
  const last_log = _lastLog()

  const handleDownloadGetImgProfile = async () => {
    try {
      // setLoading(true)

      const res = await qs.profile_photos.allCached(true)

      let imgs_to_download: any = Object.values(res).filter((v: string) => {
        if (v && v.indexOf('jpg') > -1) return true
        if (v && v.indexOf('jpeg') > -1) return true
        if (v && v.indexOf('png') > -1) return true
        if (v && v.indexOf('bmp') > -1) return true

        return false
      })

      setProfilesPerson(res)

      const imgs_chunk = chunkArray(imgs_to_download, 500)

      console.log('imgs_chunk', imgs_chunk)

      CefSharp.BindObjectAsync('cacheImageUtils').then(() => {
        for (let chunk of imgs_chunk) {
          // @ts-ignore
          cacheImageUtils.cache(chunk.join(',')).then(function (actualResult) {
            console.log(actualResult)
          })
        }
      })

      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  useEffect(() => {
    localPostApi_ValidateLocalServer('devices-manager/run_scheduler_jobs', {})

    AccessManagerRequirements()

    handleDownloadGetImgProfile()

    // Inital Sync
    // Is not necessary because is run in profile_photos.allCached
    //qs.people.allCached()

    qs.units.allCached()
    qs.access_drivers.cacheUpdates()

    qs_local_server.settings.all().then(data => {
      if (data) {
        if (data.anviz__status === '1') {
          // ServerAnviz.events_listener()
        }

        if (data.guarita__status === '1') {
          setTimeout(() => {
            localPostApi('linear/read_events_progressive')
          }, 4000)

        }
      }
    })

    document.addEventListener('keyup', (e) => {
      if (e.ctrlKey && e.shiftKey && e.altKey && e.keyCode == 76) {
        history.push('/admin/panel-hdw')
      }
    }, false)

    qs.user.me()
    qs.utils.ping()
    qs_local_server.utils.pingServer()
  }, [])

  const getImgProfile = (log: any) => {
    try {
      if (profiles_person !== null) {
        return profiles_person[log.original_personable_id || log.personable_id]
      }
    } catch (e) {
      console.warn('ERROR: get_img_profile', e)
      return null
    }
  }

  return (
    <PageBase title="Monitoramento" create={false} showFilter={false}>
      <Grid>
        <Grid.Column width={4}>
          <ProfileCache src={getImgProfile(last_log)} className="ui medium rounded image"/>
        </Grid.Column>

        <Grid.Column width={12}>
          <Header as="h1" size={'huge'} style={{ paddingTop: 5 }}>
            {last_log?.name} &nbsp; &nbsp;
            {last_log?.panic && <Label color="red" size="big" horizontal>PÂNICO</Label>}
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header sub>Destino:</Header>
                <span>
                  {last_log?.unit?.with_block ?? last_log?.unit_name}
                </span>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header sub>Data:</Header>
                <span>{hasValue(last_log, 'date') &&
                  moment(hasValue(last_log, 'date')).format('DD/MM/YYYY HH:mm:ss')}</span>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header sub>Evento:</Header>
                <span>{last_log?.event}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <Header sub>Leitor:</Header>
                <span>
                  {last_log?.access_device?.name}
                  {last_log?.access_relay?.name && <React.Fragment> ({last_log.access_relay.name})</React.Fragment>}
                </span>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header sub>Dispositivo:</Header>
                <span>
                  {last_log?.driver_type !== 'Senha' && last_log?.driver_code}<br/>
                  {last_log?.driver_type}
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>

      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>Data</Table.HeaderCell>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <Table.HeaderCell>Evento</Table.HeaderCell>
            <Table.HeaderCell>Destino</Table.HeaderCell>
            <Table.HeaderCell>Leitor</Table.HeaderCell>
            <Table.HeaderCell>Dispositivo</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {last_log && data?.map(item => (
            <Table.Row key={item.id} error={item.panic}>
              <Table.Cell>
                <Header as="h4" image>

                  <ProfileCache src={getImgProfile(item)} className="ui massive rounded image"/>

                  <Header.Content>
                    {item.name}
                    <p style={{ fontSize: '11px' }}>
                      #{item.id} -
                      Pessoa
                      # {item.personable_id} {item.original_personable_id !== item.personable_id && ` - ${item.original_personable_id}`}
                    </p>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell>
                {moment(item.date).format('DD/MM/YYYY HH:mm:ss')}
              </Table.Cell>
              <Table.Cell>
                {item.person_type}
              </Table.Cell>
              <Table.Cell>
                {item.event}
              </Table.Cell>
              <Table.Cell>
                {item?.unit?.with_block ?? item?.unit_name}
              </Table.Cell>
              <Table.Cell>
                {hasValue(item, 'access_device.name')}
                {hasValue(item, 'access_relay.name') &&
                  <React.Fragment>
                    ({hasValue(item, 'access_relay.name')})
                  </React.Fragment>}
              </Table.Cell>
              <Table.Cell>
                {item.driver_type !== 'Senha' && item.driver_code}<br/>
                {item.driver_type}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <ActivityLoading visible={loading}/>
    </PageBase>
  )
}

export default LivePage

