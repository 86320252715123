import enviroment from '../enviroment'
import { apiAxios } from '../services/api'
import { putCache } from '../utils/LocalApi'
import { fetchBaseQuery } from './query_utils'

interface Datum {
  id: number;
  block: Block;
  with_block: string;
  deleted: null;
  created_at: string;
  updated_at: string;
  code: string;
  status: number;
  type: number;
  area: null;
  residents: number | null;
  street: null;
  uuid: string;
  static_debtor: boolean;
  updated_by: number | null;
  deleted_by: null;
  block_id: number;
  updated_by_id: number | null;
  deleted_by_id: null;
}

interface Block {
  id: number;
  deleted: null;
  created_at: string;
  updated_at: string;
  name: string;
  status: number;
  uuid: string;
  created_by: number;
  updated_by: number;
  deleted_by: null;
  created_by_id: number;
  updated_by_id: number;
  deleted_by_id: null;
}

const cache_key = 'UNITS'

const fetchQuery = fetchBaseQuery<Datum>({
  key: cache_key,
  url: 'admin/units?include[]=block.id&sort[]=id',
  // six hours
  stale_time: 60 * 60 * 6
})

export const units = {
  allCached: async () => {
    const data = await fetchQuery.allCached()

    try {
      // Add backwards integration with old server version
      putCache('units', data, { timeout: 600 * 1000 })
    } catch (e) { }

    if (Array.isArray(data)) {
      data.sort((a, b) => a.with_block.localeCompare(b.with_block))
    }

    return data
  },
  getCachedDataById: async (item_id: number | string) => {
    const data = await fetchQuery.getCachedData()

    try {
      if (data) {
        return data[item_id]
      }
    } catch (e) {
      console.error(e)
      return null
    }
  },
  quick_unit_data: async (unit_id: number) => {
    interface HTTPResponse {
      id: number;
      block: Block;
      people: Person[];
      parkings: Parking[];
      with_block: string;
      deleted: null;
      created_at: string;
      updated_at: string;
      code: string;
      status: number;
      type: number;
      area: null;
      residents: number;
      street: null;
      uuid: string;
      static_debtor: boolean;
      updated_by: number;
      deleted_by: null;
      block_id: number;
      updated_by_id: number;
      deleted_by_id: null;
    }

    interface Block {
      id: number;
      deleted: null;
      created_at: string;
      updated_at: string;
      name: string;
      status: number;
      uuid: string;
      created_by: number;
      updated_by: number;
      deleted_by: null;
      created_by_id: number;
      updated_by_id: number;
      deleted_by_id: null;
    }

    interface Parking {
      id: number;
      with_pavement: string;
      deleted: null;
      created_at: string;
      updated_at: string;
      code: string;
      type: number;
      status: number;
      uuid: string;
      created_by: number;
      updated_by: number;
      deleted_by: null;
      unit_id: number;
      parking_pavement_id: number;
      unit_renter_id: number;
      renter_id: number;
      created_by_id: number;
      updated_by_id: number;
      deleted_by_id: null;
    }

    interface Person {
      id: number;
      name: string;
      status: number;
      person_role: number;
      type_person: number;
      email: null | string;
      cpf: null | string;
      rg: null | string;
      birthday: null | string;
      gender: null | string;
      kinship: null | string;
      phone: null;
      phone_commercial: null;
      mobile1: null | string;
      mobile2: null;
      profession: null;
      marital: null;
      same_address_condominium: boolean;
      address: null;
      district: null;
      city: null;
      state: null;
      cep: null | string;
      country: null | string;
      contact_name: null | string;
      contact_phone: null | string;
      contact_kinship: null | string;
      pivot_status: number;
      pivot_type: number;
      id_in_equipment: number | null;
      parent_id?: number;
      parent_name?: string;
    }

    // edit
    const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + `admin/units/${unit_id}?include[]=people.name&include[]=parkings.with_pavement`)
    return data
  }
}