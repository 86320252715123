import React, { useEffect, useState } from 'react'
import { Button, Label } from 'semantic-ui-react'
import ServerServiceManager from '../services/ServerServiceManager'
import { isCondoIdDesktop } from '../utils/common'

const ServerServiceController = (props: { info_only?: boolean, show_install?: boolean }) => {
  const [status, setStatus] = useState('service_not_installed')
  const [is_server_installed, setServerInstalled] = useState(false)
  const [db_running, setDBRunning] = useState(false)

  const is_desktop = isCondoIdDesktop()

  const updateParams = () => {
    if (typeof CefSharp !== 'undefined') {
      ServerServiceManager.getServiceStatus().then((service_status) => {
        // console.log('service_status', service_status)
        setStatus(service_status)
      })

      ServerServiceManager.isServerInstalled().then((installed) => {
        // console.log('server_installed', installed)
        setServerInstalled(installed)
      })

      ServerServiceManager.getDBServiceStatus().then(({ postgres_status, mongo_status }) => {
        // console.log('db_running', { postgres_status, mongo_status })
        setDBRunning(postgres_status && mongo_status)
      })
    }
  }

  useEffect(() => {
    updateParams()

    let inter = setInterval(() => {
      updateParams()
    }, 2000)

    return () => clearInterval(inter)
  }, [])

  let status_title = {
    ContinuePending: 'Servidor Pendente',
    Paused: 'Servidor Pausado',
    PausePending: 'Servidor Pausando',
    Running: 'Servidor Rodando',
    StartPending: 'Servidor Iniciando',
    Stopped: 'Servidor Parado',
    StopPending: 'Servidor Parando',
    service_not_installed: 'Serviço não instalado',
    error: 'Erro'
  }

  let status_color = {
    ContinuePending: 'yellow',
    Paused: 'brown',
    PausePending: 'yellow',
    Running: 'green',
    StartPending: 'yellow',
    Stopped: 'red',
    StopPending: 'yellow',
    service_not_installed: '',
    error: ''
  }

  if (!is_desktop) {
    return <></>
  }

  if (props.info_only) {
    return (
      <div style={{ marginTop: '20px' }}>
        <Label basic size={'huge'}>
          <Label
            color={is_server_installed ? db_running ? status_color[status] : 'red' : status_color[status]}
            horizontal circular empty size="big"
          />
          {
            is_server_installed
              ?
              db_running ? status_title[status] : 'SERVIDOR NÃO INICIADO'
              :
              status_title[status]
          }
        </Label>
      </div>
    )
  }

  return (
    <div className={'server-service-container'}>
      <div>
        <Label basic>
          <Label
            color={is_server_installed ? db_running ? status_color[status] : 'red' : status_color[status]}
            horizontal circular empty
          />
          {
            is_server_installed
              ?
              db_running ? status_title[status] : 'SERVIDOR NÃO INICIADO'
              :
              status_title[status]
          }
        </Label>
      </div>

      <br/>

      {status !== 'service_not_installed' &&
        <div className={'server-service-btns'}>
          <Button
            content="Iniciar/Reiniciar" type="button"
            onClick={() => ServerServiceManager.fullStartServer()}
          />

          <Button
            content="Parar" type="button"
            onClick={() => ServerServiceManager.stopService()}
          />
        </div>}

      <br/>

      {props.show_install && is_server_installed &&
        <div className={'server-service-btns'}>
          <Button
            content="Instalar Serviço" color="grey" basic type="button"
            onClick={() => ServerServiceManager.install()}
          />

          <Button
            content="Desinstalar Serviço" color="grey" basic type="button"
            onClick={() => ServerServiceManager.uninstall()}
          />
        </div>}
    </div>
  )
}

export default ServerServiceController